/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400..700&display=swap');

body {
  font-family: "Montserrat", serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
h1, h2, h3, h4, h5, h6, p, span, strong {
  font-family: "Montserrat", serif !important;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700 !important;
}
a,button {
  cursor: pointer;
  font-family: "Montserrat", serif;
}
a.skip-link {
  position: absolute;
  padding: 1px 8px;
  background-color: #ff8100;
  color: #091a28;
  border-radius: 0 0 8px 8px;
  font-size: 0.75rem;
  font-weight: 500;
  left: 50%;
  transform: translateX(-50%);
  top: -24px;
  transition: 0.3s ease all;
  text-decoration-skip-ink: none;
}
a.skip-link:hover, a.skip-link:focus{
  top: 0;
}
.fancyFont {
  font-family: "Montserrat", serif;
}

.center-vertically {
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-link {
  color: #003594;
  text-decoration: none;
}

.bg-dark-blue {
  background-color: #003594;
  
}

.bg-org {
  background-color: #ff8100;
}

.bg-color-dark {
  background-color: #0f2940;
  color: white;
}

.text-org {
  color: #ff8100;
}

.bg-light-gray {
  background-color: #F6F6F6;
}

.btn-orange {
  background-color: #ff8100;
  color: white;
  padding: 8px 55px;
  border-radius: 8px;
  border: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
}

  .btn-orange:hover {
    background-color: white;
    color: #e57200;
  }

.btn-orange-outline {
  background-color: white;
  color: #ff8100;
  padding: 8px 36px;
  border-radius: 8px;
  border: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid #FF8100;
}

.btn-orange-outline:hover {
  background-color: #e57200;
  color: rgb(227, 227, 227);
}

.btn-blue {
  background-color: #003594;
  color: #ffffff;
  padding: 8px 55px;
  border-radius: 8px;
  border: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
}
.btn-blue:hover {
  background-color: #002e84;
  color: #ffffff;
}
.btn-blue-outline {
  background-color: #ffffff;
  color: #003594;
  padding: 8px 36px;
  border-radius: 8px;
  border: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid #003594;
}
.btn-blue-outline:hover {
  background-color: #003594;
  color: #ffffff;
}

  .user-listing-wrp {
    background: #F5F5F5;
    padding: 24px 0 16px;
}
h2 {
    color: #333333;
    font-weight: 700;
    font-size: 1.375rem;
}
.breadcrumb ul {
    margin: 0;
    list-style: none;
    padding: 0;
}
.breadcrumb ul li a {
    color: #003594;
    font-size: 0.875rem;
    font-weight: 600;
}
.breadcrumb ul li span {
    color: #6F6969;
    font-size: 0.875rem;
    font-weight: 400;
}
.breadcrumb ul li {
    margin-right: 8px;
}
.card {
    border: 0;
    border-radius: 8px;
}
.table-head-wrp  h6 {
    font-size: 1rem;
    font-weight: 400;
}
.srch-bx {
    margin-right: 16px;
    position: relative;
}
.srch-bx input {
    border-radius: 6px;
    padding-left: 45px;
    font-size: 0.875rem;
    font-weight: 500;
    height: 48px;
    min-width: 250px;
    width: 100%;
}
.srch-bx img {
    position: absolute;
    top: 13px;
    left: 16px;
    max-width: 20px;
}
.btn-filter {
    height: 48px;
    border-radius: 6px;
    min-width: 150px;
    background: #D7D7D7 url('assets/icons/icon-filter.svg') no-repeat left 16px center;
}

@media only screen and (max-width: 700px) {
  .hideOnMobile {
    display: none;
  }

  .px-5 {
    /* padding-left: 5px !important;
        padding-right: 5px !important; */
  }
}

@media only screen and (max-width: 480px) {
  .top-logo {
    width: 100% !important;
    max-width: 115px;
    position: relative;
    top: 6px;
  }
}

.white-space-prewrap {
  white-space: pre-wrap;
}

.btn-outline-primary {
  border-color: #003594;
  color: #003594;
}

  .btn-outline-primary:hover,.btn-outline-primary:focus,.btn-outline-primary:active {
    background-color: #003594;
    color: #fff;
    border-color: #003594;
  }

    .btn-outline-primary:hover img {
      filter: brightness(0) invert(1);
    }
/*---Form---*/
.form-label {
  color: #6F6969;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

  .form-label span {
    color: #BD0000;
  }

.form-control {
  background-color: #ECECEC;
  border-radius: 12px;
  height: 48px;
  font-size: 0.875rem;
  border: 1px solid #ECECEC;
}

  .form-control:focus {
    box-shadow: none !important;
    background: #ECECEC;
  }

.modal h3 {
  font-size: 1.125rem;
  font-weight: 700;
}

select.form-control {
  background: #ECECEC url('assets/icons/select-drpdwn.svg') no-repeat right 10px center !important;
}

textarea.form-control {
resize: none;
height: 80px !important;
}
.form-control.is-invalid {
  border: 1px solid red !important;
}

.text-danger {
  font-size: 14px;
}



/*.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3;*/ /* Light grey */
  /*border-top: 8px solid #3498db;*/ /* Blue */
  /*border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}*/

/*.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);*/ /* Light grey */
  /*border-top: 8px solid #3498db;*/ /* Blue */
  /*border-radius: 50%;
  width: 50px;*/ /* Increased size for better visibility */
  /*height: 50px;*/ /* Increased size for better visibility */
  /*animation: spin 1.2s linear infinite;*/ /* Faster spin for a modern effect */
/*}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}*/


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8); /* Light background */
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-top: 8px solid #007bff; /* Bootstrap primary color */
  border-radius: 50%;
  width: 60px; /* Larger size for better visibility */
  height: 60px; /* Larger size for better visibility */
  animation: spin 1s linear infinite; /* Smooth spinning animation */
}
.mat-mdc-text-field-wrapper {
  border-radius: 16px !important;
  background-color: #ECECEC !important;
  height: 48px !important;
}
 
.mat-mdc-form-field-infix{
  padding-top: 12px !important;
  min-height: 48px !important;
  padding-bottom: 12px !important;
}
 
.mat-mdc-select-placeholder{
  font-size: 0.875rem !important;
}
 
.mdc-text-field--filled .mdc-line-ripple::before, .mdc-text-field--filled .mdc-line-ripple::after{
  border: none !important;
}
 
.mat-mdc-form-field-subscript-wrapper{
  display: none !important;
 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control ~ .text-danger {
  font-size: 14px;
  margin-top: 2px;
  color: #A4281C !important;
}

.responsive-table {
  overflow-x: auto;
  white-space: nowrap;

}
.responsive-table table th,.responsive-table table td {
  white-space: nowrap;
}
.btn-green-outline {
  height: 36px;
  background: #fff;
  border: 1px solid #22c55e;
  color: #22c55e;
  margin-left: 8px;
  font-weight: 600;
}
.btn-red-outline {
  height: 36px;
  background: #fff;
  border: 1px solid #A4281C;
  color: #A4281C;
  margin-left: 8px;
  font-weight: 600;
}
button.btn-outline-grey{
  border: 1px solid #999999;
  background: transparent;
  color: #999999 !important;
  font-size: 0.875rem;
  font-weight: 600;
  height: 40px;
  min-width: 110px;
  border-radius: 8px;
}
button.btn-outline-grey:hover,button.btn-outline-grey:focus{
  border: 1px solid #6F6969;
  color: #6F6969 !important;
}
button.btn-red {
  background: #A4281C !important;
  border-color: #A4281C !important;
  height: 40px;
  min-width: 110px;
  border-radius: 8px;
}
button.btn-primary {
  background: #003594;
  color: #ffffff;
  border: none;
  height: 40px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
}
button.btn-primary:hover,button.btn-primary:focus{
  background: #002e84;
  color: #ffffff;
}
.mat-mdc-dialog-content {
  padding: 16px !important;
}
.mat-mdc-dialog-actions.button-group {
  padding: 16px 30px;
  border-top: 1px solid #dee2e6;
}
.mat-mdc-dialog-surface {
  border-radius: 16px !important;
}
.btn-close.mat-mdc-icon-button.mat-mdc-button-base {
  font-size: 17px;
}
.btn-close.mat-mdc-icon-button.mat-mdc-button-base:focus {
  box-shadow: none;
}
.btn-close.mat-mdc-icon-button.mat-mdc-button-base:active {
  background-color: #fff !important;
  border-radius: 0;
}
.close-icon {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 24px; /* Adjust icon size as needed */
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54); /* Default color for Material icons */
}

/* tab style */

.mat-mdc-tab-links{

  border-radius: 8px;
      background: #f5f5f5;
  }
  
  
  .mdc-tab--active {
      background: #003594 !important;
      border-radius: 8px;
      margin: 5px !important;
      /* color: #fff; */
  }
  span.mdc-tab__text-label {
    font-weight: 600;
    color: #333 !important;
  }
  .mdc-tab--active  span.mdc-tab__text-label {
      color: #fff !important;
  }
 
  span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
      display: none;
  }

  .mdc-tab__text-label{
    position: relative;
    top:5px;
  }
  .mdc-tab--active  .mdc-tab__text-label {
    top:0;
  }

  .mat-mdc-tab-link.mdc-tab {
    height: 38px !important;
}

.text-danger{
  font-family: "Montserrat", serif !important;
}

@media screen and (max-width:1450px) {
  .nav-link {
    font-size: 14px !important;
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}
ul {
  list-style: none;
  margin:0;
  padding: 0;
}

.new-details-desc ul {
  list-style: disc;
  margin-left: 30px;
}

.st-area {
  border: 1px solid #ECECEC !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background-color: #ECECEC !important;
  height: 300px !important;
}
.st-toolbar {
  border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

/* Pagination */
.pagination button{
    border-radius: 8px;
    font-size: 16px;
    padding: 10px 10px;
    min-width: 48px;
    min-height: 48px;
}
.pagination button.page-box {
  background: #fff;
  border: 1px solid #D7D7D7;
  color: #333333;
  margin: 0 5px;
  cursor: pointer;
}
.pagination button.page-box.active {
  background: #003594;
  border-color: #003594;
}
.pagination button.arrow-btn {
  background: #fff;
  border: 1px solid #D7D7D7;
  color: #333333;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
}
.pagination button.arrow-btn:disabled{
  background-color: #D7D7D7;
}
.dropdown button.dropdown-toggle.show,.dropdown button.dropdown-toggle:active{
  border: none;
}
.dropdown-menu .list-group a.list-group-item {
  background-color: #ffffff;
  color: #6F6969;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 0;
  padding: 0.5rem 1rem;
}
.dropdown-menu .list-group a.list-group-item:hover, .dropdown-menu .list-group a.list-group-item:focus {
  background-color: #F5F4FB;
  color: #003594;
}

/*no records*/
.no-records-message {
  padding: 20px;
  text-align: center;
  color: #777;
}

.outline-badge-primary{
  background-color: #ffffff;
  border: 1px solid #003594;
  color: #003594;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.badge-primary-tag{
  background-color: rgba(70, 64, 222, 0.10);
  border: none;
  color: #003594;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}
@media only screen and (max-width: 767.98px){
  ::-webkit-scrollbar {
    width: 8px;
  }
}
::-webkit-scrollbar-track {
  background-color: #d5dbe4;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #aaaaaa; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,53,148,.66);
}

button.btn-green-outline:hover {
  background-color: #166534 !important;
  color: white !important;
 }
 
 button.btn-red-outline:hover {
  background-color: darkred !important;
  color: white !important;
 }